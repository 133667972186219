import { DatePipe, UpperCasePipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  forwardRef,
  input,
  isDevMode,
  numberAttribute,
  signal,
  ViewEncapsulation,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatOptionModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectChange, MatSelectModule } from "@angular/material/select";
import { generateTimeRange } from "@app/utils";
import { millisecondsToMinutes, startOfDay } from "date-fns";
import { InlineSVGModule } from "ng-inline-svg-2";

@Component({
  standalone: true,
  selector: "roam-time-picker",
  styleUrl: "roam-time-picker.component.scss",
  templateUrl: "roam-time-picker.component.html",
  host: { style: "display: block" },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoamTimePickerComponent),
      multi: true,
    },
  ],
  imports: [
    DatePipe,
    UpperCasePipe,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    InlineSVGModule,
  ],
})
export class RoamTimePickerComponent implements ControlValueAccessor {
  readonly now = new Date();
  readonly label = input("Select Time");
  readonly placeholder = input("Select Time");
  readonly step = input(30, { transform: numberAttribute });
  readonly dateFormat = input("hh:mm a");
  readonly minuteOptions = computed(() => {
    return generateTimeRange(this.now, this.step()).map(date => {
      const totalMinutes = millisecondsToMinutes(
        date.getTime() - startOfDay(date).getTime()
      );
      return {
        value: totalMinutes,
        label: date,
      };
    });
  });
  protected isDisabled = signal(false);
  protected value = signal(0);

  onChange = (v: number) => this.value.set(v);
  onTouch: () => void = () => {};

  private findClosestMinutes(totalMinutes: number): number {
    const opt = this.minuteOptions().reduce((prev, curr) => {
      return (
          Math.abs(curr.value - totalMinutes) <
            Math.abs(prev.value - totalMinutes)
        ) ?
          curr
        : prev;
    });
    return opt.value;
  }

  writeValue(value: number): void {
    const newValue = this.findClosestMinutes(value);
    if (isDevMode()) {
      console.log("RoamTimePicker » WriteValue: ", value, newValue);
    }
    this.value.set(newValue);
  }

  registerOnChange(onChangeCallbackFn: () => void): void {
    this.onChange = onChangeCallbackFn;
  }

  registerOnTouched(onTouchCallbackFn: () => void): void {
    this.onTouch = onTouchCallbackFn;
  }

  setDisabledState(value: boolean): void {
    this.isDisabled.set(value);
  }
}
