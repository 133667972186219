<mat-form-field appearance="outline" class="roam-mdc-form-field">
  <mat-label>{{ label() }}</mat-label>
  <mat-select
    [(value)]="value"
    [disabled]="isDisabled()"
    [placeholder]="placeholder()"
    (selectionChange)="onChange($event.value)"
    (blur)="onTouch()"
    panelClass="roam-mdc-select-panel roam-time-picker-panel"
    [hideSingleSelectionIndicator]="false">
    @for (opt of minuteOptions(); track opt) {
      <mat-option class="roam-mdc-option" [value]="opt.value">
        <span class="roam-text-nowrap">
          {{ opt.label | date: dateFormat() }}
        </span>
      </mat-option>
    }
  </mat-select>
  <button matSuffix mat-icon-button type="button">
    <span inlineSVG="assets/svg/clock.svg"></span>
  </button>
</mat-form-field>
